export const ResultsStateFactory = (base) => class Results extends base {
    _handleUp() {
        this._setState('Filters');
    }
    _getFocused() {
        return this.tag('Results') || this;
    }
    $scrolledRow(rowId) {
        const hideElements = rowId < 2;
        this.widgets.menu.visible = hideElements;
        this.tag('Filters').patch({ visible: hideElements });
    }
};
