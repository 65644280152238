import { Lightning } from '@lightningjs/sdk';
import { parseMarkdownBold } from '../helpers';
import { COLORS, FONT_FACE, FLEX_DIRECTION, ALIGN_ITEMS } from '../constants';
const LINE_HEIGHT = 30;
const commonStyles = {
    fontSize: 26,
    textColor: COLORS.white6,
    lineHeight: 31,
};
const regularObject = {
    flexItem: { marginRight: 5 },
    text: Object.assign(Object.assign({}, commonStyles), { fontFace: FONT_FACE.light, lineHeight: LINE_HEIGHT }),
};
const boldObject = {
    flexItem: { marginRight: 5 },
    text: Object.assign(Object.assign({}, commonStyles), { fontFace: FONT_FACE.semiBold }),
};
const baseObject = (index) => ({
    x: 0,
    y: index * 28,
    flex: { direction: FLEX_DIRECTION.row },
});
export default class Benefit extends Lightning.Component {
    static _template() {
        return {
            h: 80,
            flex: {
                direction: FLEX_DIRECTION.row,
                alignItems: ALIGN_ITEMS.center,
                paddingY: 7,
            },
            Image: {
                flexItem: {
                    marginRight: 13,
                },
                src: this.bindProp('image'),
            },
            Title: { w: 400, h: 66 },
        };
    }
    get title() {
        return this._title;
    }
    set title(title) {
        const titleAsArray = Array.isArray(title) ? title : [title];
        const titleAsString = Array.isArray(title) ? title.join(' ') : title;
        this._title = titleAsString.replace(/\*\*/g, '').replace(/\n/g, '');
        titleAsArray.forEach((t, index) => {
            const parsedMarkdown = parseMarkdownBold(t, regularObject, boldObject, baseObject(index));
            this.tag('Title').patch({
                [`Title${index}`]: index > 0 ? Object.assign(Object.assign({}, parsedMarkdown), { y: LINE_HEIGHT }) : parsedMarkdown,
            });
        });
    }
}
