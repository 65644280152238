import { Lightning } from '@lightningjs/sdk';
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../constants';
export default class TextButton extends Lightning.Component {
    static _template() {
        return {
            w: (w) => w,
            h: (h) => h,
            Shadow: {
                alpha: 0,
                color: COLORS.black7,
                x: -10,
                y: -10,
            },
            BG: {
                x: (w) => w / 2,
                y: (h) => h / 2,
                mount: 0.5,
            },
            Label: {
                x: (w) => w / 2,
                y: (h) => h / 2 + 3,
                mount: 0.5,
                text: {
                    textAlign: TEXT_ALIGN.center,
                },
            },
        };
    }
    _construct() {
        this._radius = 40;
        this._strokeWidth = 0;
        this._strokeColor = COLORS.transparent;
        this._fontSize = 36;
        this._fontFace = FONT_FACE.regular;
        this._focusFontColor = COLORS.dark;
        this._unfocusFontColor = COLORS.lightGray3;
        this._focusBackGroundColor = COLORS.lightGray3;
        this._unfocusBackgroundColor = COLORS.dark;
        this._label = '';
        this._autoWidth = false;
        this._paddingLeft = 30;
        this._paddingRight = 30;
        this._letterSpacing = 1;
        this._unfocusBgAlpha = 1;
        this._unfocusLabelAlpha = 1;
    }
    _init() {
        if (this._autoWidth) {
            this.tag('Label').on('txLoaded', () => {
                if (this._autoWidth) {
                    this._labelRenderWidth = this.tag('Label').renderWidth;
                    this._calculateButtonWidth();
                }
            });
        }
        else {
            this._updateButton();
        }
    }
    _firstActive() {
        this._setLabel();
    }
    set radius(v) {
        this._radius = v;
    }
    set strokeWidth(v) {
        this._strokeWidth = v;
    }
    set strokeColor(v) {
        this._strokeColor = v;
    }
    get label() {
        return this._label;
    }
    set label(v) {
        if (this._label !== v) {
            this._label = v;
            this._setLabel();
            this._setAnnounce();
        }
    }
    set fontSize(v) {
        this._fontSize = v;
    }
    set fontFace(v) {
        this._fontFace = v;
    }
    set letterSpacing(letterSpacing) {
        this._letterSpacing = letterSpacing;
    }
    get letterSpacing() {
        return this._letterSpacing;
    }
    get unfocusBgAlpha() {
        return this._unfocusBgAlpha;
    }
    set unfocusBgAlpha(unfocusBgAlpha) {
        this._unfocusBgAlpha = unfocusBgAlpha;
    }
    get unfocusLabelAlpha() {
        return this._unfocusLabelAlpha;
    }
    set unfocusLabelAlpha(unfocusLabelAlpha) {
        this._unfocusLabelAlpha = unfocusLabelAlpha;
    }
    set focusFontColor(focusFontColor) {
        this._focusFontColor = focusFontColor;
    }
    set unfocusFontColor(unfocusFontColor) {
        this._unfocusFontColor = unfocusFontColor;
    }
    set focusBackGroundColor(focusBackGroundColor) {
        this._focusBackGroundColor = focusBackGroundColor;
    }
    set unfocusBackgroundColor(unfocusBackgroundColor) {
        this._unfocusBackgroundColor = unfocusBackgroundColor;
    }
    set autoWidth(v) {
        this._autoWidth = v;
    }
    set padding(v) {
        this._paddingLeft = v;
        this._paddingRight = v;
    }
    set paddingLeft(v) {
        this._paddingLeft = v;
    }
    set paddingRight(v) {
        this._paddingRight = v;
    }
    _setAnnounce() {
        this.announce = this._label;
    }
    _setLabel() {
        this.patch({
            Label: {
                text: {
                    fontSize: this._fontSize,
                    text: this._label,
                    textColor: this._isFocus ? this._focusFontColor : this._unfocusFontColor,
                    fontFace: this._fontFace,
                    letterSpacing: this._letterSpacing,
                },
            },
        });
    }
    _calculateButtonWidth() {
        this.w = this._labelRenderWidth + this._paddingLeft + this._paddingRight;
        this._updateButton();
    }
    _updateButton() {
        const [bgColor, textColor, bgAlpha, labelAlpha] = this._isFocus
            ? [this._focusBackGroundColor, this._focusFontColor, 1, 1]
            : [
                this._unfocusBackgroundColor,
                this._unfocusFontColor,
                this._unfocusBgAlpha,
                this._unfocusLabelAlpha
            ];
        this.patch({
            Shadow: {
                alpha: 0,
            },
            BG: {
                alpha: bgAlpha,
                texture: Lightning.Tools.getRoundRect(this.w, this.h, this._radius, this._strokeWidth, this._strokeColor, true, bgColor),
            },
            Label: {
                alpha: labelAlpha,
                text: {
                    textColor,
                },
            },
        });
        if (this._isFocus) {
            this.tag('Shadow').patch({
                alpha: 1,
                w: this.w + 20,
                h: this.h + 20,
                texture: Lightning.Tools.getShadowRect(this.w + 20, this.h + 20, 10, 40, 30),
            });
        }
    }
    _focus() {
        this._isFocus = true;
        this._updateButton();
    }
    _unfocus() {
        this._isFocus = false;
        this._updateButton();
    }
}
