import RouterUtil from '../../../../util/RouterUtil';
import pageAttributes from './pageAttributes';
import { CREDITS_FLOW_MODAL_TYPE, MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import { getBrandName } from '../../../../helpers';
import ModalManager from '../../../ModalManager';
import { getCurrentModalInstance } from '../../../../widgets/Modals';
const pageAttributeFallback = {
    name: MPARTICLE_DEFAULT_ATTR_VALUE,
    type: MPARTICLE_DEFAULT_ATTR_VALUE,
};
const getPageAttributes = (path = null, current = true) => {
    if (!path)
        path = current ? RouterUtil.current() : RouterUtil.getPrevRoute();
    const page = typeof path === 'string' ? pageAttributes[path] : undefined;
    const attributes = (page === null || page === void 0 ? void 0 : page[getBrandName()]) || (page === null || page === void 0 ? void 0 : page.default);
    if (!attributes)
        return pageAttributeFallback;
    if (!!ModalManager._activeModal) {
        const currentModal = getCurrentModalInstance();
        if (currentModal._type === CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED ||
            currentModal._type === CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED) {
            attributes.name = currentModal === null || currentModal === void 0 ? void 0 : currentModal.pageName;
        }
    }
    else {
        if (typeof (attributes === null || attributes === void 0 ? void 0 : attributes.name) === 'function')
            return Object.assign(Object.assign({}, attributes), { name: attributes.name() });
    }
    return attributes;
};
export const getReferrer = () => {
    return getPageAttributes(null, true).name;
};
export const getCurrentPageType = () => {
    return getPageAttributes().type;
};
export default getPageAttributes;
