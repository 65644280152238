import { Lightning } from '@lightningjs/sdk';
import { ALIGN_ITEMS, COLORS, FLEX_DIRECTION, VERTICAL_ALIGN } from '../../../../../constants';
export default class PeacockBenefit extends Lightning.Component {
    static _template() {
        return {
            h: 96,
            flex: {
                direction: FLEX_DIRECTION.row,
                alignItems: ALIGN_ITEMS.center,
                paddingY: 7,
            },
            Image: {
                flexItem: {
                    marginRight: 28,
                },
                src: this.bindProp('image'),
            },
            Title: {
                text: {
                    text: this.bindProp('title'),
                    fontSize: 26,
                    textColor: COLORS.white,
                    lineHeight: 31,
                    wordWrapWidth: 400,
                    maxLines: 3,
                    verticalAlign: VERTICAL_ALIGN.middle,
                }
            },
        };
    }
}
