import CreditsFlowTile from '../components/CreditsFlowTile';
import { ALIGN_ITEMS, COLORS, CREDITS_FLOW_MODAL_TYPE, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, VERTICAL_ALIGN, } from '../../../../constants';
import { Language } from '@lightningjs/sdk';
import PeacockTile from '../components/PeacockTile/PeacockTile';
import ModalManager, { ModalTypes } from '../../../../lib/ModalManager';
import { ACTIVATION_LANDING } from '../../activation/constants';
import TVPlatform from '../../../../lib/tv-platform';
import { EVENTS } from '../../../../lib/analytics/types';
import { sendMetric } from '../../../../lib/analytics/Analytics';
const ITEM_CLICKED_NAME = 'Link TV Provider';
const getDivider = () => ({
    skipFocus: true,
    h: 173,
    w: 51,
    flex: {
        direction: FLEX_DIRECTION.column,
        justifyContent: JUSTIFY_CONTENT.center,
        alignItems: ALIGN_ITEMS.center,
    },
    Top: {
        rect: true,
        h: 59,
        w: 1,
        color: COLORS.white,
    },
    Or: {
        flexItem: {
            marginTop: 6,
        },
        text: {
            text: Language.translate('or'),
            fontSize: 42,
            fontFace: FONT_FACE.light,
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
        },
    },
    Bottom: {
        rect: true,
        h: 59,
        w: 1,
        color: COLORS.white,
    },
});
const getLinkMVPDComponent = (stream, program, resolveCallback, closeCallback, flowType) => ({
    type: CreditsFlowTile,
    title: Language.translate('credits_flow_mvpd_title'),
    btnTitle: Language.translate('credits_flow_mvpd_btn_title'),
    imgSrc: 'images/credits-flow-locker-icon.png',
    onClick: () => {
        if (flowType === CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED ||
            flowType === CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED) {
            sendMetric(EVENTS.CLICK, {
                name: ITEM_CLICKED_NAME,
                brand: program.brand.title,
                show: program.series,
                season: program.seasonNumber,
            });
        }
        ModalManager.open(ModalTypes.CREDITS_FLOW_ACTIVATION, {
            landing: ACTIVATION_LANDING.MVPD,
            stream,
            program,
            resolveCallback,
            closeCallback,
        }, false);
    },
});
const getPeacockComponent = (program) => ({
    type: PeacockTile,
    btnTitle: Language.translate(TVPlatform.isExitToPeacockSupported() ? 'stream-now' : 'peacock-cta-ok').toUpperCase(),
    benefitTitle1: Language.translate('peacock-tile-benefit-1'),
    benefitTitle2: Language.translate('peacock-tile-benefit-2'),
    program: program,
});
export const restrictedAuthorized = (stream, program, resolveCallback, closeCallback) => {
    return {
        title: Language.translate('credits_flow_auth_restricted_title'),
        template: {
            LinkMVPD: getLinkMVPDComponent(stream, program, resolveCallback, closeCallback, CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED),
            Divider: getDivider(),
            PeacockTile: getPeacockComponent(program),
        },
    };
};
export const restrictedUnauthorized = (stream, program, resolveCallback, closeCallback) => {
    return {
        title: Language.translate('credits_flow_unauth_restricted_title'),
        template: {
            LinkMVPD: getLinkMVPDComponent(stream, program, resolveCallback, closeCallback, CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED),
            Divider: getDivider(),
            PeacockTile: getPeacockComponent(program),
        },
    };
};
export const unrestrictedAuthorized = (stream, program, resolveCallback, closeCallback) => {
    return {
        title: Language.translate('credits_flow_auth_unrestricted_title'),
        template: {
            LinkMVPD: getLinkMVPDComponent(stream, program, resolveCallback, closeCallback),
            Divider: getDivider(),
            PeacockTile: getPeacockComponent(),
        },
    };
};
export const unrestrictedUnauthorized = (stream, program, resolveCallback, closeCallback) => {
    return {
        title: Language.translate('credits_flow_unauth_unrestricted_title'),
        template: {
            LinkProfile: {
                type: CreditsFlowTile,
                title: Language.translate('credits_flow_unrestricted_profile_title'),
                btnTitle: Language.translate('credits_flow_unrestricted_profile_btn_title'),
                imgSrc: 'images/credits-flow-profile-icon.png',
                onClick: () => {
                    ModalManager.open(ModalTypes.CREDITS_FLOW_ACTIVATION, {
                        landing: ACTIVATION_LANDING.NBC,
                        stream,
                        program,
                        resolveCallback: () => {
                            resolveCallback(true);
                        },
                        closeCallback,
                    }, false);
                },
            },
            Divider: getDivider(),
            LinkMVPD: getLinkMVPDComponent(stream, program, resolveCallback, closeCallback),
        },
    };
};
