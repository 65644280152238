import { Router } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import { getTimeDiffAsMilliseconds, DEFAULT_TIME_LIMIT_UPCOMING_ITEM } from '../../../helpers';
import RouterUtil from '../../../util/RouterUtil';
import { ROUTE, ROUTE_ENTITY } from '../../../constants';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
import { preCheckByPid, preCheckRoutes } from './precheck';
import { openPlayerLoader } from '../../../widgets/Modals/playerLoader/PlayerLoader';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { clearState } from '../../../store/PlayerStore/actions';
export const navigateUpcomingLiveItems = async ({ item, fireAncestors, preventClickMetric, routerBackDisabled, openLoader = false, }) => {
    const { channelId, streamAccessName, callSign, startTime, isLive, upcomingModal, customerPlayableDate, shelf, pid, } = item;
    const eventHasStarted = isLive || getTimeDiffAsMilliseconds(moment(), startTime) <= DEFAULT_TIME_LIMIT_UPCOMING_ITEM;
    if (!eventHasStarted) {
        ModalManager.open(ModalTypes.UPCOMING, Object.assign({ upcomingModal: upcomingModal, customerPlayableDate: customerPlayableDate }, (shelf ? { shelf } : {})));
        return;
    }
    if (openLoader)
        openPlayerLoader();
    if (pid) {
        try {
            const params = { preventClickMetric };
            if (preCheckRoutes.includes(Router.getActiveRoute())) {
                const response = await preCheckByPid(pid, routerBackDisabled);
                if (response && response.authType)
                    params.preauth = response.authType;
            }
            await PlayerStoreSingleton.dispatch(clearState());
            RouterUtil.navigateToRoute(ROUTE.watch, { entity: ROUTE_ENTITY.pid, value: pid }, params);
        }
        catch (_a) {
            //
        }
    }
    else {
        RouterUtil.navigateToLive(fireAncestors, {
            channelId,
            streamAccessName,
            callSign,
        }, preventClickMetric);
    }
};
