import { Language, Lightning, Log } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE, LANGUAGES } from '../../../constants';
import { generateTextElementsByHtml } from './helpers';
import QR from 'qrcode';
const WRAPPER_HEIGHT = 776;
const CONTENT_OFFSET = 20;
const SCROLL_STEP = 175;
export class VerticalText extends Lightning.Component {
    static _template() {
        return {
            h: 776,
            w: 1246,
            Title: {
                x: 107,
                color: COLORS.black,
                text: {
                    fontFace: FONT_FACE.bold,
                    fontSize: 48,
                    wordWrapWidth: 1061,
                    text: this.bindProp('title'),
                },
            },
            Content: {
                h: 776,
                w: 1246,
                x: 107,
                y: 0,
                clipping: true,
                ContentText: {
                    w: (w) => w,
                    flex: { direction: FLEX_DIRECTION.column },
                },
            },
            LineSeparator: {
                w: 1100,
                h: 2,
                x: 90,
                y: 525,
                rect: true,
                color: COLORS.dark11,
                alpha: 0,
            },
            QRTextContainer: {
                x: 107,
                y: 535,
                color: COLORS.black,
                zIndex: 100,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 33,
                    text: this.bindProp('textQRCode'),
                },
                alpha: 0,
            },
            QRContainer: {
                y: 580,
                x: 107,
                w: 180,
                h: 180,
                zIndex: 100,
                alpha: 0,
            },
        };
    }
    get focusable() {
        return true;
    }
    get announce() {
        return this._announce;
    }
    set announce(announceText) {
        this._announce = this.title + ', ' + announceText;
    }
    set content(text) {
        const htmlBody = new DOMParser().parseFromString(text, 'text/html').body;
        this.announce = htmlBody.innerText;
        this.tag('ContentText').children = Array.prototype.map
            .call([htmlBody], generateTextElementsByHtml)
            .flat(Infinity);
    }
    set urlQRCode(url) {
        QR.toDataURL(url)
            .then((QRCodeURL) => {
            this.tag('QRContainer').src = QRCodeURL;
        })
            .catch((error) => {
            Log.error('qrcode error: ', error);
        });
    }
    _init() {
        this.tag('Title').on('txLoaded', () => {
            const h = this.tag('Title').renderHeight;
            this.tag('Content').patch({
                h: 776 - h,
                y: h,
            });
        });
    }
    _updateUIElements(show) {
        const alpha = show ? 1 : 0;
        this.tag('LineSeparator').patch({ alpha });
        this.tag('QRContainer').patch({ alpha });
        this.tag('QRTextContainer').patch({ alpha });
    }
    _handleDown() {
        if (this.tag('ContentText').finalH < WRAPPER_HEIGHT + CONTENT_OFFSET)
            return false;
        const maxScrollY = this.tag('ContentText').finalH + CONTENT_OFFSET - WRAPPER_HEIGHT;
        const isMaxScroll = this._isMaxScroll();
        if (isMaxScroll) {
            const lang = Language.get();
            this.tag('ContentText').patch({
                y: lang === LANGUAGES.SPANISH ? -850 : -550,
            });
            this._updateUIElements(true);
            return false;
        }
        const nextScrollPosition = isMaxScroll ? -maxScrollY : this.tag('ContentText').y - SCROLL_STEP;
        this._scrollTo(Math.floor(nextScrollPosition));
    }
    _handleUp() {
        if (Math.floor(this.tag('ContentText').finalY) === 0)
            return;
        this._updateUIElements(false);
        this._scrollTo(this.tag('ContentText').y + SCROLL_STEP >= 0 ? 0 : this.tag('ContentText').y + SCROLL_STEP);
    }
    _scrollTo(y) {
        if (y !== this.tag('ContentText').finalY) {
            this.tag('ContentText').patch({
                y,
            });
        }
    }
    _isMaxScroll() {
        const contentText = this.tag('ContentText');
        const currentScrollY = contentText.y - SCROLL_STEP;
        const finalY = contentText.finalY;
        const isSpanish = Language.get() === LANGUAGES.SPANISH;
        const maxScrollThreshold = isSpanish ? -875 : -525;
        const finalYThreshold = isSpanish ? 0 : -175;
        return currentScrollY <= maxScrollThreshold && (!finalYThreshold || finalY <= finalYThreshold);
    }
}
