import { Lightning, Registry, Router, Utils } from '@lightningjs/sdk';
import ItemsBar from './ItemsBar';
import ProfileItem from './ProfileItem';
import { animateMvpdLogo, checkSetMvpdLogo, getBrandName, setSmooth } from '../../helpers';
import ContentPosition from '../../util/contentPosition';
import { COLORS, FLEX_DIRECTION, PAGE_NAME, ROUTE, SCREEN_SIZE } from '../../constants';
import GlobalNavigationSingleton from '../../helpers/globalNavigation';
export default class Menu extends Lightning.Component {
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: 165,
            Background: {
                w: (w) => w,
                h: (h) => h,
                TopHalf: {
                    rect: true,
                    w: (w) => w,
                    h: (h) => h / 2,
                    y: 0,
                    color: COLORS.black,
                },
                BottomHalf: {
                    rect: true,
                    w: (w) => w,
                    h: (h) => h / 2,
                    y: (h) => h / 2,
                    colorTop: COLORS.black,
                    colorBottom: COLORS.transparent,
                },
            },
            Holder: {
                x: 80,
                y: 61,
                flex: {
                    direction: FLEX_DIRECTION.row,
                },
                Logo: {
                    src: Utils.asset(`images/logos/logo-${getBrandName()}-small.png`),
                },
                ItemsBar: {
                    flexItem: {
                        marginLeft: 20,
                    },
                    type: ItemsBar,
                },
            },
            MvpdLogo: {
                y: 90,
                x: 1670,
                mountY: 0.5,
                src: null,
                alpha: 0,
            },
            ProfileItem: {
                y: 90,
                x: 1782,
                mountY: 0.5,
                type: ProfileItem,
            },
        };
    }
    _init() {
        GlobalNavigationSingleton.consumer(this);
        this.tag('MvpdLogo').on('txLoaded', (texture) => {
            const margin = 30;
            if (this.tag('MvpdLogo').x + texture.source.w + margin >= this.tag('ProfileItem').x)
                this.tag('MvpdLogo').x = this.tag('ProfileItem').x - texture.source.w - margin;
        });
    }
    _firstActive() {
        this._setState('Items');
    }
    _active() {
        super._active();
        this.showHideMvpdLogo();
    }
    get itemsBar() {
        return this.tag('ItemsBar');
    }
    get profileItem() {
        return this.tag('ProfileItem');
    }
    set items(items) {
        this.itemsBar.items = items;
        this._setState('Items');
    }
    set currentButton(route) {
        this.itemsBar.items.forEach((child) => {
            child.current = child.item.route === route;
        });
        this.profileItem.update();
    }
    collapse(args) {
        this.itemsBar.collapse(args);
        if (args.hideProfile) {
            this.profileItem.visible = false;
        }
    }
    expand() {
        this.itemsBar.expand();
        this.profileItem.visible = true;
    }
    _handleBack(e) {
        var _a, _b, _c;
        if ((_a = Router.getActiveRoute()) === null || _a === void 0 ? void 0 : _a.includes(PAGE_NAME.replays.toLowerCase())) {
            ContentPosition.clearPositionForCurrentPage();
        }
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        (_c = (_b = Router.getActivePage().widgets.menu).onBack) === null || _c === void 0 ? void 0 : _c.call(_b);
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assignable to parameter of type 'ROUTE'.
        if ([ROUTE.home, ROUTE.olympics, ROUTE.paralympics].includes(Router.getActiveRoute())) {
            if (this.itemsBar.focusItem._index === 0) {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                Router.getActivePage().widgets.exitdialog.visible = true;
                Router.focusWidget('ExitDialog');
            }
            else {
                const currentFocus = this._getFocused();
                if (currentFocus === this.profileItem)
                    this._setState('Items');
                this.itemsBar.moveFocus(-this.itemsBar.focusIndex);
            }
        }
        else {
            Router.navigate(ROUTE.home);
        }
        e.preventDefault();
        e.stopPropagation();
    }
    _handleUp() {
        return true;
    }
    _handleDown() {
        Router.focusPage();
    }
    _handleLeft() {
        return true;
    }
    showHideMvpdLogo(show = true) {
        if (!show)
            return animateMvpdLogo(this, false);
        checkSetMvpdLogo(this);
    }
    focusProfileItem() {
        this._setState('Profile');
        this.itemsBar.setActiveIndex(this.itemsBar.items.length - 1);
    }
    $onEnterCallback() {
        var _a, _b;
        ContentPosition.clearPositionForCurrentPage(true);
        const activePage = Router.getActivePage();
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        if ((_b = (_a = activePage === null || activePage === void 0 ? void 0 : activePage.widgets) === null || _a === void 0 ? void 0 : _a.menu) === null || _b === void 0 ? void 0 : _b.onEnter) {
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            activePage.widgets.menu.onEnter();
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            activePage.widgets.menu.onEnter = null;
        }
        // @ts-expect-error TS(2339): Property '_endMediaSession' does not exist on type... Remove this comment to see the full error message
        if (activePage === null || activePage === void 0 ? void 0 : activePage._endMediaSession) {
            // @ts-expect-error TS(2339): Property '_endMediaSession' does not exist on type... Remove this comment to see the full error message
            activePage._endMediaSession();
            // @ts-expect-error TS(2339): Property '_endMediaSession' does not exist on type... Remove this comment to see the full error message
            activePage._endMediaSession = null;
        }
    }
    hideBackground() {
        setSmooth(this.tag('Background'), 'alpha', 0);
    }
    showBackground() {
        setSmooth(this.tag('Background'), 'alpha', 1);
    }
    _onActivated(page) {
        const symbol = Router.symbols['route'];
        if (symbol) {
            const route = page[symbol];
            Registry.setTimeout(() => {
                const items = this.itemsBar.items;
                const index = items.findIndex((child) => child.item.route === route) ||
                    items.findIndex((child) => child.visible);
                if (index > -1)
                    this.itemsBar.setActiveIndex(index);
                this.profileItem.update();
            }, 0);
        }
    }
    static _states() {
        return [
            class Items extends this {
                _checkFocusState() {
                    if (!this.itemsBar.hasVisibleItems) {
                        if (this.profileItem.visible) {
                            this._setState('Profile');
                        }
                        else {
                            Router.restoreFocus();
                        }
                    }
                }
                $enter() {
                    this._checkFocusState();
                }
                _focus() {
                    this._checkFocusState();
                }
                _getFocused() {
                    return this.itemsBar || this;
                }
                _handleRight() {
                    if (this.profileItem.visible)
                        this._setState('Profile');
                }
            },
            class Profile extends this {
                _focus() {
                    if (!this.profileItem.visible)
                        this._setState('Items');
                }
                _getFocused() {
                    return this.profileItem || this;
                }
                _handleLeft() {
                    this._setState('Items');
                }
            },
        ];
    }
}
