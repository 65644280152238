import moment from 'moment-timezone';
import getVodAttributes, { getResumeAttributes, getTokenType } from '../attributes/getVodAttributes';
import { setDefaultValue } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getVideoEndAttributes = (params) => {
    var _a, _b, _c;
    const { analytics, authType, watched, duration, ccLanguage, tokenType, referrer, resumeTime, previousVideo, previousVideoType, endType, } = params;
    // Unclear where/how we'd get a referrer since it's exit of video
    // To Do: maybe another ticket to track where the entry came from and store.
    const watchedDuration = moment.duration(watched, 'seconds');
    return setDefaultValue(Object.assign(Object.assign(Object.assign({}, getVodAttributes(params, ccLanguage, previousVideo, previousVideoType, analytics)), getResumeAttributes({ resumeTime })), { 'Token Type': ((_a = analytics === null || analytics === void 0 ? void 0 : analytics.authFunnel) === null || _a === void 0 ? void 0 : _a.tokenType) || tokenType || getTokenType(authType), 'Video End Type': endType || 'Close', 'Duration Watched': Math.round(watchedDuration.asMilliseconds()), '% Complete': Math.round((watched / duration) * 100), Casting: MPARTICLE_DEFAULT_ATTR_VALUE, Sponsor: (_c = (_b = referrer === null || referrer === void 0 ? void 0 : referrer.components) === null || _b === void 0 ? void 0 : _b.shelf) === null || _c === void 0 ? void 0 : _c.sponsorName, 'Video Duration': params.durationInMilliseconds }), MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getVideoEndAttributes;
