import { Language } from '@lightningjs/sdk';
import { ROUTE, ROUTE_ENTITY } from '../../../../constants';
import RouterUtil from '../../../../util/RouterUtil';
import { ACTIVATION_TYPE } from '../constants';
import { ActivationStrategy } from './ActivationStrategy';
import { getUserProfile } from '../../../../api/Identity';
import ModalManager, { CloseReason } from '../../../../lib/ModalManager';
export class CreditsFlowActivationStrategy extends ActivationStrategy {
    drawText() {
        const title = Language.translate(this._ctx.titleTextKey);
        this._ctx.tag('Title').text.text = title;
        return [title];
    }
    _redirect() {
        if (this._ctx._streamId) {
            const userProfile = getUserProfile();
            const shouldGoToCredits = this._activationType === ACTIVATION_TYPE.NBC &&
                !(userProfile === null || userProfile === void 0 ? void 0 : userProfile.isVideoAlreadyPlayed(this._ctx._streamId));
            if (shouldGoToCredits)
                RouterUtil.navigateToRoute(ROUTE.credits, {
                    entity: ROUTE_ENTITY.videoId,
                    value: this._ctx._streamId,
                });
            else
                ModalManager.close(CloseReason.COMPLETED);
        }
    }
}
