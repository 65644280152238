import { Language } from '@lightningjs/sdk';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import { CHANNEL_IDS, geoCheck } from './Live';
import { getUserProfile, saveUserProfile } from './Identity';
import { removeHardcodedMPID, setAppLaunchTime } from '../helpers';
import AnalyticsSingleton from '../lib/analytics/Analytics';
import GlobalNavigationSingleton from '../helpers/globalNavigation';
import { GraphQlClient } from '../graphql/client';
import TVPlatform from '../lib/tv-platform';
import AuthenticationSingleton from '../authentication/Authentication';
import { ErrorType } from '../lib/tv-platform/types';
import { ConsentManagement } from '../lib/cmp/ConsentManagement';
const VERSION_ADS_METADATA = 'v1';
/**
 * Removes one profile token and adds episode to episodesArray
 * @param mpxGuid
 */
export const reduceEpisodeCount = async (mpxGuid) => {
    try {
        const { brandSource, id, rev } = getUserProfile();
        const { url, username, password, apiVersion } = AppConfigFactorySingleton.config.access_vod.idm_access;
        await fetch(url, {
            method: 'PATCH',
            headers: new Headers({
                'X-IDM-Brand-Source': brandSource,
                idm_tx_ref: `from-access-${new Date().getTime().toString()}`,
                'X-IDM-Update-Rev': rev,
                'X-IDM-Username': username,
                'X-IDM-Password': password,
                'api-version': apiVersion,
                'X-IDM-Target-ID': id,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify([
                {
                    operation: 'increment',
                    field: 'episodeCount',
                    value: -1,
                },
                {
                    operation: 'add',
                    field: '/episodeArray/-',
                    value: mpxGuid,
                },
            ]),
        });
        await saveUserProfile();
    }
    catch (e) {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            description: 'Failed to call IDM',
            payload: e,
        });
    }
};
export const getText = (url, bustCache = false) => {
    const isJson = url === null || url === void 0 ? void 0 : url.includes('.json');
    return fetch(url, {
        method: 'GET',
        cache: bustCache ? 'no-cache' : 'default',
    })
        .then((response) => {
        if (isJson) {
            return response.json();
        }
        else {
            return response.text();
        }
    })
        .then((response) => {
        if (response.content && response.title) {
            let txt = response.title + '\n';
            response.content.forEach((content) => {
                if (content.paragraph) {
                    txt += content.paragraph;
                }
            });
            return txt;
        }
        return response;
    })
        .catch((e) => {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            description: 'Error fetching LongText content',
            payload: e,
        });
    });
};
export const getSettingsPageData = (page) => {
    return fetch(AppConfigFactorySingleton.config[page][Language.get()], {
        method: 'GET',
    }).then((res) => res.json());
};
const checkGeoByChannelIds = async (ids) => {
    if (!(ids === null || ids === void 0 ? void 0 : ids.length))
        return;
    await Promise.all(ids.map((id) => geoCheck(id)));
};
export const bootApp = async () => {
    try {
        removeHardcodedMPID();
        // Perform initial critical tasks first
        GraphQlClient.cache.gc();
        const analyticsPromise = AnalyticsSingleton.initialize();
        const saveUserProfilePromise = saveUserProfile();
        const authInitPromise = AuthenticationSingleton.init()
            .then(async () => await AuthenticationSingleton.checkStatus())
            .then(async () => await LaunchDarklySingleton.initialize());
        const geoCheckPromise = checkGeoByChannelIds([
            CHANNEL_IDS.nbc,
            CHANNEL_IDS.usa,
            CHANNEL_IDS.telemundo,
        ]);
        // Wait for other concurrent operations to complete
        await Promise.all([analyticsPromise, saveUserProfilePromise, authInitPromise, geoCheckPromise]);
        await ConsentManagement.sync();
        await GlobalNavigationSingleton.load();
        setAppLaunchTime();
    }
    catch (error) {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            description: 'Boot error app',
            payload: error,
        });
        throw error;
    }
};
export const getAddMetadata = ({ adId, renditionId, csid }) => {
    const { ads_metadata_url: baseUrl, api_timeout: timeout, uplynk = {}, } = AppConfigFactorySingleton.config;
    const url = `${baseUrl}/${VERSION_ADS_METADATA}/${adId}/${renditionId}/${csid || (uplynk === null || uplynk === void 0 ? void 0 : uplynk.fwAdConfigCSID)}`;
    return fetch(url, {
        timeout,
    })
        .then((response) => response.json())
        .catch((err) => Promise.reject(err));
};
