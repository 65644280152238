import { Router } from '@lightningjs/sdk';
import { preCheckByCallSign, preCheckRoutes } from './precheck';
import { LiveStreamManager } from '../../../lib/LiveStreamManager';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { setEpgChannel } from '../../../store/PlayerStore/actions/epg';
import TVPlatform from '../../../lib/tv-platform';
var ErrorType;
(function (ErrorType) {
    ErrorType["NETWORK"] = "network";
    ErrorType["MEDIA"] = "media";
    ErrorType["RESTRICTION"] = "restriction";
    ErrorType["ENTITLEMENT"] = "entitlement";
    ErrorType["OTHER"] = "other";
})(ErrorType || (ErrorType = {}));
export const navigateLinearItem = async ({ stream, routerBackDisabled }) => {
    try {
        const { channelId, streamAccessName, callSign, machineName } = stream;
        if (preCheckRoutes.includes(Router.getActiveRoute())) {
            const payload = await preCheckByCallSign(callSign, routerBackDisabled);
            if (payload) {
                LiveStreamManager.set(channelId, streamAccessName, callSign);
                await PlayerStoreSingleton.dispatch(setEpgChannel(channelId, streamAccessName, machineName));
            }
        }
    }
    catch (error) {
        if (error)
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                code: 'Player - EPG',
                description: 'unable to load stream data',
                payload: stream === null || stream === void 0 ? void 0 : stream.callSign,
            });
        throw error;
    }
};
