import { Language } from '@lightningjs/sdk';
import OnboardingActivation from './OnboardingActivation';
import { EVENTS } from '../../../lib/analytics/types';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { LoadingStateFactory } from './states/LoadingStateFactory';
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory';
import { ContentGatingType } from '../../../api/types/bffTypes';
export default class ContentGatingActivation extends OnboardingActivation {
    constructor() {
        super(...arguments);
        this._titleTextKey = 'content_gating_activation_title';
        this._routerBackDisabled = false;
        this._type = ContentGatingType.SOFT;
        this._shouldContinueWatching = false;
        this._closeCallback = () => {
            if (this._type === ContentGatingType.SOFT && this._shouldContinueWatching) {
                return this._resolveCallback();
            }
            return this._rejectCallback();
        };
    }
    _active() {
        // Closing modal does not destroy it, keeping prev state, so need to reset
        this._shouldContinueWatching = false;
    }
    _setModalData(modalData) {
        if (modalData.type) {
            this._type = modalData.type;
            this._setCancelButtonTranslation();
        }
        if (modalData.rejectCallback)
            this._rejectCallback = modalData.rejectCallback;
        if (modalData.resolveCallback)
            this._resolveCallback = modalData.resolveCallback;
        modalData.closeCallback = this._closeCallback;
        super._setModalData(modalData);
    }
    _setCancelButtonTranslation() {
        var _a;
        (_a = this.tag('Content.CancelButton')) === null || _a === void 0 ? void 0 : _a.patch({
            label: Language.translate(this._type === ContentGatingType.SOFT ? 'maybe-later' : 'no-thanks').toUpperCase(),
        });
    }
    _setTranslation() {
        super._setTranslation();
        this._setCancelButtonTranslation();
    }
    static _states() {
        return [
            LoadingStateFactory(this),
            NewCodeStateFacrory(this),
            class Cancel extends this {
                _getFocused() {
                    return this.tag('CancelButton');
                }
                _handleEnter() {
                    sendMetric(EVENTS.CLICK, {
                        name: this._type === ContentGatingType.SOFT ? 'Maybe Later' : 'No Thanks',
                    });
                    if (this._type === ContentGatingType.SOFT) {
                        this._shouldContinueWatching = true;
                    }
                    super._handleBack();
                }
                _handleUp() {
                    this._setState('NewCode');
                }
            },
        ];
    }
}
