import { Lightning } from '@lightningjs/sdk';
import { COLORS } from '../../../../../constants';
import LinearGradientShader from '../../../../../shaders/LinearGradientShader';
export default class PeacockGradientButton extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.unfocusBgAlpha = 1;
        this.unfocusLabelAlpha = 1;
    }
    static _template() {
        return {
            rect: true,
            rtt: true,
            clipping: true,
            w: 420,
            h: 50,
            color: COLORS.transparent,
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 24,
            },
            FocusedBackground: {
                rect: true,
                w: (w) => w,
                h: (h) => h,
                visible: false,
                shader: {
                    type: LinearGradientShader,
                    stops: [0.01, 0.5, 1],
                    colors: [COLORS.yellow5, COLORS.yellow3, COLORS.yellow4],
                    angle: 180,
                }
            },
            UnfocusedBackground: {
                alpha: this.bindProp('unfocusBgAlpha'),
                texture: Lightning.Tools.getRoundRect(416, 46, 22, 2, COLORS.yellow5, true, COLORS.transparent),
            },
            Title: {
                x: (w) => w / 2,
                y: (h) => h / 2 + 3,
                mount: 0.5,
                text: {
                    text: this.bindProp('title'),
                    fontSize: 26,
                    textColor: COLORS.white,
                    letterSpacing: 0.8,
                }
            }
        };
    }
    _setFocusedParams(focused) {
        this.tag('FocusedBackground').patch({
            visible: focused,
        });
        this.tag('UnfocusedBackground').patch({
            visible: !focused,
        });
        const [textColor, alpha] = focused
            ? [COLORS.black, 1]
            : [COLORS.white, this.unfocusLabelAlpha];
        this.tag('Title').patch({
            alpha,
            text: {
                textColor,
            }
        });
    }
    _focus() {
        this._setFocusedParams(true);
    }
    _unfocus() {
        this._setFocusedParams(false);
    }
}
