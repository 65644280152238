import { Log } from '@lightningjs/sdk';
import { get } from 'lodash';
//Player
import { PlayerStatus } from '../model/PlayerStatus';
//Events
import { BufferEndEvent, BufferStartEvent, DebugEvent, ErrorEvent, FatalErrorEvent, MutablePlayerEventStream, PlayerStatusEvent, TimeChangeEvent, SeekStartEvent, SeekEndEvent, } from '../model/event';
//Helpers and utils
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore';
import { isVod } from '../../components/player/helpers/metadata';
import { PlayerEventEmitterRegistry, PlayerEventSetup, } from '../model/emitter/PlayerEventEmitterRegistry';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
//constants
import { PROGRAMMING_TYPES } from '../../constants';
//Log const
const PLAYER_INTERFACE_TAG = 'Player Interface';
export var PlayerState;
(function (PlayerState) {
    PlayerState[PlayerState["INIT"] = 0] = "INIT";
    PlayerState[PlayerState["LOADING"] = 1] = "LOADING";
    PlayerState[PlayerState["BUFFERING"] = 2] = "BUFFERING";
    PlayerState[PlayerState["RECOVERING"] = 3] = "RECOVERING";
    PlayerState[PlayerState["PLAYING"] = 4] = "PLAYING";
    PlayerState[PlayerState["ERROR"] = 5] = "ERROR";
})(PlayerState || (PlayerState = {}));
export var PlayerMode;
(function (PlayerMode) {
    PlayerMode["FULL"] = "full";
    PlayerMode["MINIMAL"] = "minimal";
})(PlayerMode || (PlayerMode = {}));
export class PlayerInterface {
    constructor(playerMode, options) {
        var _a;
        this._domId = 'video-player';
        // Player state
        this._state = PlayerState.INIT;
        this._playerStatus = PlayerStatus.UNKNOWN;
        // This is the internal event stream which we can subscribe from the view
        // and is populated with events sent from the emitters and the PlayerInterface
        this._normalizedPlayerEvents = new MutablePlayerEventStream();
        // Asset
        this._id = 0;
        this._videoUrl = '';
        //Time
        this._lastPosition = 0;
        this._savedSeekPosition = 0;
        this._startTime = -1;
        // Errors
        this._recoverMediaCount = 0;
        this._onError = (error = {}) => { };
        this._options = options;
        if (options === null || options === void 0 ? void 0 : options.domId)
            this._domId = options === null || options === void 0 ? void 0 : options.domId;
        this._playerMode = playerMode;
        this._playerEventEmitterRegistry = new PlayerEventEmitterRegistry(playerMode === PlayerMode.FULL ? PlayerEventSetup.ALL : PlayerEventSetup.MINIMAL);
        this._subscription = (_a = this.events) === null || _a === void 0 ? void 0 : _a.subscribe(this._onEventReceived.bind(this));
    }
    get status() {
        return this._playerStatus;
    }
    get normalizedPlayerEvents() {
        if (!this._normalizedPlayerEvents) {
            this._normalizedPlayerEvents = new MutablePlayerEventStream();
        }
        return this._normalizedPlayerEvents;
    }
    get events() {
        return this.normalizedPlayerEvents.events();
    }
    get isLoading() {
        return this._state === PlayerState.LOADING;
    }
    get isBuffering() {
        return this._state === PlayerState.BUFFERING;
    }
    get isRecovering() {
        return this._state === PlayerState.RECOVERING;
    }
    _onEventReceived(event) {
        if (event instanceof PlayerStatusEvent) {
            this._playerStatus = event.status;
            switch (event.status) {
                case PlayerStatus.READY:
                    if (this._playerDomEl) {
                        this._playerDomEl.style.visibility = 'visible';
                    }
                    break;
                case PlayerStatus.LOADING:
                    this._onLoading();
                    break;
                case PlayerStatus.PLAYING:
                    this._state = PlayerState.PLAYING;
                    if (this._recoverMediaCount)
                        this._recoverMediaCount = 0;
                    if (this._playerDomEl) {
                        this._playerDomEl.style.visibility = 'visible';
                    }
                    break;
                default:
                    break;
            }
        }
        else if (event instanceof ErrorEvent) {
            this._onError(event.error);
        }
        else if (event instanceof BufferStartEvent) {
            this._onBufferStart();
        }
        else if (event instanceof BufferEndEvent) {
            this._onBufferEnd();
        }
        else if (event instanceof DebugEvent) {
            Log.info(`${PLAYER_INTERFACE_TAG} debug event ${event.name}`, event.data);
        }
        else if (event instanceof TimeChangeEvent) {
            this._lastPosition = event.time;
        }
        else if (event instanceof SeekStartEvent) {
            this._onSeekStart();
        }
        else if (event instanceof SeekEndEvent) {
            this._onSeekEnd();
        }
    }
    completeEvents() {
        this.events.complete();
    }
    _detach() {
        var _a;
        this._clearSession();
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    _clearSession() {
        var _a;
        if (this._playerDomEl) {
            this._playerDomEl.style.display = 'none';
        }
        (_a = this._playerEventEmitterRegistry) === null || _a === void 0 ? void 0 : _a.detach();
        this._playerEventEmitterRegistry = null;
        if (this._bufferingTimeout)
            clearTimeout(this._bufferingTimeout);
        if (this._loadingInterval)
            clearInterval(this._loadingInterval);
    }
    clearPreviousSession() {
        this._clearSession();
    }
    close() {
        this._detach();
    }
    isPlayingAd() {
        return false;
    }
    _onLoading() {
        if (this.isLoading)
            return;
        this._state = PlayerState.LOADING;
        const maxRetry = get(AppConfigFactorySingleton.config, 'hls_player.recoverMediaMaxRetry', 3);
        if (this._loadingInterval) {
            clearInterval(this._loadingInterval);
        }
        this._loadingInterval = window.setInterval(() => {
            var _a;
            if (this.isLoading && this._recoverMediaCount < maxRetry) {
                // Attempt to recover media.
                this._recoverMediaCount += 1;
                Log.error(`${PLAYER_INTERFACE_TAG} recover media count ${this._recoverMediaCount}`);
                this._onRecoverMediaError();
            }
            else {
                // Playback resumed or unable to recover media.
                if (this._loadingInterval)
                    clearInterval(this._loadingInterval);
                if (this._recoverMediaCount === maxRetry) {
                    const description = `${PLAYER_INTERFACE_TAG} unable to recover media, redirect to error page.`;
                    TVPlatform.reportError({
                        type: ErrorType.MEDIA,
                        code: PLAYER_INTERFACE_TAG,
                        description,
                    });
                    (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new FatalErrorEvent({ fatal: true, description, code: '404' }));
                    this._recoverMediaCount = 0;
                }
            }
        }, get(AppConfigFactorySingleton.config, 'hls_player.recoverErrorTimeout', PlayerInterface.RECOVER_ERROR_TIMEOUT));
    }
    _onBufferEnd() {
        Log.info(`${PLAYER_INTERFACE_TAG} buffer end`);
        this._state = PlayerState.PLAYING;
        if (this._bufferingTimeout)
            clearTimeout(this._bufferingTimeout);
    }
    _onSeekEnd() {
        Log.info(`${PLAYER_INTERFACE_TAG} seek end`);
        this._onBufferEnd();
    }
    _onSeekStart() {
        Log.info(`${PLAYER_INTERFACE_TAG} seek end`);
        this._onBufferStart();
    }
    _onBufferStart() {
        if (this.isBuffering)
            return;
        Log.info(`${PLAYER_INTERFACE_TAG} buffer start`);
        this._state = PlayerState.BUFFERING;
        if (this._bufferingTimeout)
            clearTimeout(this._bufferingTimeout);
        this._bufferingTimeout = window.setTimeout(() => {
            Log.error(`${PLAYER_INTERFACE_TAG} buffering, attempt to recover media`);
            this._onRecoverMediaError();
            this._state = PlayerState.ERROR;
        }, get(AppConfigFactorySingleton.config, 'hls_player.bufferingTimeout', 8000));
    }
    getSafeSeekPosition(positionInMilliseconds) {
        var _a;
        Log.warn(`${PLAYER_INTERFACE_TAG} get safe seek position called on interface`);
        const programmingType = (_a = PlayerStoreSingleton.program) === null || _a === void 0 ? void 0 : _a.programmingType;
        if (programmingType === PROGRAMMING_TYPES.FER || isVod(programmingType))
            return positionInMilliseconds;
        return -1;
    }
    setVideoSize(left, top, width, height) { }
    setVisibility(visible) { }
    _onRecoverMediaError() { }
    // These need to be overridden
    isPlaying() {
        return false;
    }
    seek(positionInMilliseconds) { }
    play() { }
    pause() { }
    seekToLiveEdge() { }
    get version() {
        return '';
    }
    setCCStyle(options) { }
    setMute(mute) { }
    setVolume(volume) { }
}
PlayerInterface.RECOVER_ERROR_TIMEOUT = 2000;
