import { Log } from '@lightningjs/sdk';
import { PlayerEventEmitter } from './PlayerEventEmitter';
import { AudioTrackEvent, AudioTracksEvent, BingeEndcardEvent, FatalErrorEvent, PlayerStatusEvent, SeekEndEvent, SeekStartEvent, SubtitleEvent, TimeChangeEvent, TrickPlayImageEvent, } from '../event';
import { PlayerStatus } from '../PlayerStatus';
import { SessionState, } from '@sky-uk-ott/core-video-sdk-js-core';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { executeAfterJitterDelay } from '../../../util/jitter';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import { END_CARD_FER_TRIGGER_TIME } from '../../../constants';
import { isBlockingModalOpen } from '../../../lib/ModalManager';
const CORE_EMITTER_TAG = 'Core Video Emitter';
export class CorePlayerStatusEmitter extends PlayerEventEmitter {
    constructor(allowedEvents) {
        super();
        this._allowedEvents = [
            PlayerStatusEvent,
            FatalErrorEvent,
            SubtitleEvent,
            TimeChangeEvent,
            SeekStartEvent,
            SeekEndEvent,
            TrickPlayImageEvent,
            BingeEndcardEvent,
            AudioTracksEvent,
            AudioTrackEvent,
        ];
        //#region Private
        this._onSeekStarted = () => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new SeekStartEvent({}));
        };
        this._onSeekEnded = () => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new SeekEndEvent({}));
        };
        this._onAvailableThumbnailVariantsChanged = async (thumbnailVariants) => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new TrickPlayImageEvent(thumbnailVariants));
        };
        this._onEndOfEventMarkerReceived = () => {
            var _a, _b;
            const jitterConfig = (_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.binge) === null || _b === void 0 ? void 0 : _b.jitter;
            const sleBingeJitterMin = (jitterConfig === null || jitterConfig === void 0 ? void 0 : jitterConfig.min) || 0;
            const sleBingeJitterMax = (jitterConfig === null || jitterConfig === void 0 ? void 0 : jitterConfig.max) || 0;
            // usage when a Binge Marker is received
            executeAfterJitterDelay(() => {
                var _a;
                // Logic to request Binge Tiles from BFF
                if (!isBlockingModalOpen()) { // do not show Binge end cards when activation modal is displayed
                    (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new BingeEndcardEvent());
                }
            }, sleBingeJitterMin, sleBingeJitterMax);
        };
        this._onFEREndOfEventMarkerReceived = () => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new BingeEndcardEvent());
        };
        this._onError = (error) => {
            var _a, _b, _c, _d, _e;
            if (((_b = (_a = error === null || error === void 0 ? void 0 : error.severity) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) === 'warning') {
                Log.warn(`${CORE_EMITTER_TAG} ${JSON.stringify(error)}`);
            }
            else {
                (_c = this._normalizedPlayerEvents) === null || _c === void 0 ? void 0 : _c.publish(new FatalErrorEvent({
                    fatal: true,
                    description: (_d = error.message) !== null && _d !== void 0 ? _d : 'Fatal player error',
                    code: (_e = error.code) !== null && _e !== void 0 ? _e : '404',
                    category: error.category,
                }));
            }
        };
        this._onManifestParsed = () => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new PlayerStatusEvent(PlayerStatus.READY, {}));
        };
        this._onStateChanged = (playerState) => {
            var _a, _b, _c, _d, _e, _f;
            switch (playerState) {
                case SessionState.Loading:
                    (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new PlayerStatusEvent(PlayerStatus.LOADING, {}));
                    break;
                case SessionState.Rebuffering:
                    (_b = this._normalizedPlayerEvents) === null || _b === void 0 ? void 0 : _b.publish(new PlayerStatusEvent(PlayerStatus.BUFFERING, {}));
                    break;
                case SessionState.Paused:
                    (_c = this._normalizedPlayerEvents) === null || _c === void 0 ? void 0 : _c.publish(new PlayerStatusEvent(PlayerStatus.PAUSED, {}));
                    break;
                case SessionState.Playing:
                    (_d = this._normalizedPlayerEvents) === null || _d === void 0 ? void 0 : _d.publish(new PlayerStatusEvent(PlayerStatus.PLAYING, {}));
                    break;
                case SessionState.Seeking:
                    (_e = this._normalizedPlayerEvents) === null || _e === void 0 ? void 0 : _e.publish(new PlayerStatusEvent(PlayerStatus.SEEKING, {}));
                    break;
                case SessionState.Finished:
                    (_f = this._normalizedPlayerEvents) === null || _f === void 0 ? void 0 : _f.publish(new PlayerStatusEvent(PlayerStatus.FINISHED, {}));
                    break;
                default:
                    break;
            }
        };
        this._onAvailableSubtitlesTracksChanged = (tracks) => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new SubtitleEvent({ tracks }));
        };
        this._onSubtitleCuesChanged = (cues) => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new SubtitleEvent({ cues }));
        };
        this._onPlaybackTimelineUpdated = (timeline) => {
            var _a, _b;
            const { position, seekableRange, isAtLiveEdge } = timeline;
            if (position) {
                (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new TimeChangeEvent(position, seekableRange, isAtLiveEdge));
            }
            if (((_b = this._program) === null || _b === void 0 ? void 0 : _b.programmingType) === 'Full Event Replay' &&
                position >= this._triggerTimeInSeconds &&
                !this._ferBingeAPICall) {
                this._onFEREndOfEventMarkerReceived();
                this._ferBingeAPICall = true;
            }
        };
        this._onAudioTracksChanged = (data) => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AudioTracksEvent(data));
        };
        this._onAudioTrackChanged = (track) => {
            var _a;
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AudioTrackEvent(track));
        };
        if (allowedEvents)
            this._allowedEvents = allowedEvents;
        const { program } = PlayerStoreSingleton;
        this._program = program;
        this._programDuration = (program === null || program === void 0 ? void 0 : program.duration) || 0;
        this._triggerTimeInSeconds = this._programDuration - END_CARD_FER_TRIGGER_TIME;
        this._ferBingeAPICall = false;
    }
    attach(player, mutablePlayerEventStream) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        super.attach(player, mutablePlayerEventStream);
        if (!player) {
            Log.warn(`${CORE_EMITTER_TAG} unable to attach core player emitter`);
            return;
        }
        if (this._allowedEvents.includes(PlayerStatusEvent)) {
            (_a = player.onStateChanged) === null || _a === void 0 ? void 0 : _a.call(player, this._onStateChanged);
            player.onPlayoutDataReceived(this._onManifestParsed);
        }
        if (this._allowedEvents.includes(TrickPlayImageEvent)) {
            (_b = player.onAvailableThumbnailVariantsChanged) === null || _b === void 0 ? void 0 : _b.call(player, this._onAvailableThumbnailVariantsChanged);
        }
        if (this._allowedEvents.includes(TimeChangeEvent)) {
            if (player.onPlaybackTimelineUpdated) {
                player.onPlaybackTimelineUpdated(this._onPlaybackTimelineUpdated);
            }
            else {
                Log.warn(`${CORE_EMITTER_TAG} onPlaybackTimelineUpdated not found`);
            }
        }
        if (this._allowedEvents.includes(FatalErrorEvent))
            (_c = player.onError) === null || _c === void 0 ? void 0 : _c.call(player, this._onError);
        if (this._allowedEvents.includes(SubtitleEvent))
            (_d = player.onAvailableSubtitlesTracksChanged) === null || _d === void 0 ? void 0 : _d.call(player, this._onAvailableSubtitlesTracksChanged);
        if (this._allowedEvents.includes(SubtitleEvent))
            (_e = player.onSubtitleCuesChanged) === null || _e === void 0 ? void 0 : _e.call(player, this._onSubtitleCuesChanged);
        if (this._allowedEvents.includes(SeekStartEvent))
            (_f = player.onSeekStarted) === null || _f === void 0 ? void 0 : _f.call(player, this._onSeekStarted);
        if (this._allowedEvents.includes(SeekEndEvent))
            (_g = player.onSeekEnded) === null || _g === void 0 ? void 0 : _g.call(player, this._onSeekEnded);
        if (this._allowedEvents.includes(BingeEndcardEvent))
            (_h = player.onEndOfEventMarkerReceived) === null || _h === void 0 ? void 0 : _h.call(player, this._onEndOfEventMarkerReceived);
        if (this._allowedEvents.includes(AudioTracksEvent))
            (_j = player.onAvailableAudioTracksChanged) === null || _j === void 0 ? void 0 : _j.call(player, this._onAudioTracksChanged);
        if (this._allowedEvents.includes(AudioTrackEvent))
            (_k = player.onAudioTrackChanged) === null || _k === void 0 ? void 0 : _k.call(player, this._onAudioTrackChanged);
    }
}
