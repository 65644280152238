import { datadogRum } from '@datadog/browser-rum';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
/**
 * Initializes Datadog RUM with the provided configuration
 */
export function initializeDatadog() {
    var _a;
    const datadogConfig = (_a = AppConfigFactorySingleton.config.datadog) !== null && _a !== void 0 ? _a : {
        appId: '86b81123-c254-48f3-aa6a-aec3453a49be',
        client: 'pubdf756b25db3d45d835d70eabcf8e5451',
        site: 'datadoghq.com',
        service: 'lightningtest',
        env: 'stage',
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackViewsManually: true,
    };
    datadogRum.init({
        applicationId: datadogConfig.appId,
        clientToken: datadogConfig.client,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: datadogConfig.site,
        service: datadogConfig.service,
        env: datadogConfig.env,
        sessionSampleRate: datadogConfig.sessionSampleRate,
        sessionReplaySampleRate: datadogConfig.sessionReplaySampleRate,
        trackUserInteractions: datadogConfig.trackUserInteractions,
        trackResources: datadogConfig.trackResources,
        trackLongTasks: datadogConfig.trackLongTasks,
        defaultPrivacyLevel: datadogConfig.defaultPrivacyLevel,
        trackViewsManually: datadogConfig.trackViewsManually,
    });
}
/**
 * Logs an error to Datadog RUM
 */
export function logError(errorMessage, errorContext) {
    const error = new Error(errorMessage);
    datadogRum.addError(error, errorContext);
}
/**
 * Sets global context that will be attached to all subsequent RUM events
 */
export function setGlobalContext(context) {
    datadogRum.setGlobalContext(context);
}
/**
 * Sets user information for RUM events
 */
export function setUser(user) {
    datadogRum.setUser(user);
}
/**
 * Sets user information for RUM events
 */
export function setView(page) {
    datadogRum.startView({
        name: page,
    });
}
