import { Language, Lightning, Router, Utils, Storage } from '@lightningjs/sdk';
import { updateLanguage } from '../../moment';
import mParticleInterface from '../../lib/analytics/mParticle/mParticleInterface';
import SelectButton from '../../components/buttons/SelectButton';
import { COLORS, LANGUAGES, FONT_FACE, STORAGE_KEYS, ROUTE } from '../../constants';
import GlobalNavigationSingleton from '../../helpers/globalNavigation';
export default class AppLanguage extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._shouldReload = false;
    }
    static _template() {
        const langTitle = Language.translate('app_language');
        const langNote = Language.translate('select_preferred_language_message');
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: 810,
            rect: true,
            color: COLORS.dark5,
            announce: [langTitle, langNote],
            PageTitleHolder: {
                x: 100,
                y: 78,
                Icon: {
                    h: 40,
                    w: 40,
                    src: Utils.asset('images/settings/language.png'),
                },
                Title: {
                    x: 60,
                    text: {
                        text: langTitle,
                        fontSize: 30,
                    },
                },
            },
            Content: {
                y: 178,
                x: 233,
                LanguageButtons: {
                    EnglishButton: {
                        y: 10,
                        w: 740,
                        h: 70,
                        type: SelectButton,
                        radius: 0,
                        fontSize: 30,
                        fontFace: FONT_FACE.light,
                        focusFontColor: COLORS.dark,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.lightGray3,
                        unfocusBackgroundColor: COLORS.black3,
                        label: Language.translate('english'),
                        padding: 0,
                        icon: 'images/settings/selection.png',
                        selected: false,
                        optionValue: LANGUAGES.DEFAULT,
                    },
                    SpanishButton: {
                        y: 90,
                        w: 740,
                        h: 70,
                        type: SelectButton,
                        radius: 0,
                        fontSize: 30,
                        fontFace: FONT_FACE.light,
                        focusFontColor: COLORS.dark,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.lightGray3,
                        unfocusBackgroundColor: COLORS.black3,
                        label: Language.translate('spanish'),
                        padding: 0,
                        icon: 'images/settings/selection.png',
                        selected: false,
                        optionValue: 'es',
                    },
                },
                Description: {
                    x: 1013 - 230,
                    y: 10,
                    text: {
                        text: langNote,
                        wordWrapWidth: 660,
                        fontFace: FONT_FACE.light,
                        fontSize: 30,
                        lineHeight: 45,
                    },
                },
            },
        };
    }
    _init() {
        this.stage.gc();
        this.stage.setClearColor(COLORS.dark);
        this.doLanguageChange(Language.get());
    }
    _active() {
        this._collapseMenu();
        this._setState(Language.get() === LANGUAGES.DEFAULT ? 'EnglishButton' : 'SpanishButton');
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    _handleBack(e) {
        e.preventDefault();
        // Need to handle a reload on nav back if there's a language change
        // In order to get it to stick across cached.
        const reload = this._shouldReload;
        this._shouldReload = false;
        this.stage.gc();
        Router.navigate(ROUTE.settings, { reload }, false);
    }
    $valueChanged(val) {
        if (Language.get() != val) {
            this._setState('LanguageChangeConfirmation', [{ lang: val }]);
        }
    }
    getActiveButton() {
        return this.tag('LanguageButtons').children.find((button) => button.selected) || null;
    }
    resetActiveButton(lang) {
        this.tag('LanguageButtons').children.forEach((button) => {
            button.selected = button.optionValue === lang;
        });
    }
    changeLanguage(lang) {
        Storage.set(STORAGE_KEYS.APP_LANGUAGE, lang);
        Language.set(lang).then(() => {
            this.doLanguageChange(lang);
            mParticleInterface.setUserLanguageAttribute(lang);
            updateLanguage(lang);
        });
    }
    doLanguageChange(lang) {
        const langTitle = Language.translate('app_language');
        const langNote = Language.translate('select_preferred_language_message');
        this.patch({
            Content: {
                Description: {
                    text: {
                        text: langNote,
                    },
                },
                LanguageButtons: this.getEnSpButtonPatch(),
            },
            PageTitleHolder: {
                Title: { text: { text: langTitle } },
            },
        });
        this.announce = [langTitle, langNote];
        GlobalNavigationSingleton.load().then(() => this._collapseMenu());
        this._setState(lang === LANGUAGES.DEFAULT ? 'EnglishButton' : 'SpanishButton');
        this.resetActiveButton(lang);
        this._shouldReload = true;
    }
    _collapseMenu() {
        this.widgets.menu.collapse({
            subCategoryItem: Language.translate('app_language'),
            hideProfile: true,
        });
    }
    getEnSpButtonPatch() {
        const EnglishButton = {
            label: Language.translate('english'),
        };
        const SpanishButton = {
            label: Language.translate('spanish'),
        };
        return { EnglishButton, SpanishButton };
    }
    static _states() {
        return [
            class EnglishButton extends this {
                _getFocused() {
                    return this.tag('EnglishButton') || this;
                }
                _handleDown() {
                    this._setState('SpanishButton');
                }
            },
            class SpanishButton extends this {
                _getFocused() {
                    return this.tag('SpanishButton') || this;
                }
                _handleUp() {
                    this._setState('EnglishButton');
                }
            },
            class LanguageChangeConfirmation extends this {
                $enter({ prevState }, args) {
                    this._preState = prevState;
                    this._args = args;
                    this.widgets.confirmationdialog._setMessage(Language.translate('change_app_language'), Language.translate('cancel'), Language.translate('confirm_change_language'));
                    this.widgets.confirmationdialog.visible = true;
                    Router.focusWidget('ConfirmationDialog');
                }
                confirmBtnClicked() {
                    this.hideConformationDialog();
                    this.changeLanguage(this._args.lang);
                }
                cancelBtnClicked() {
                    this.hideConformationDialog();
                    this.setPreviousState();
                }
                setPreviousState() {
                    // We need to reset states based on opposite of selection to go back on cancel.
                    this._setState(this._args.lang === LANGUAGES.DEFAULT ? 'SpanishButton' : 'EnglishButton');
                    this.resetActiveButton(this._args.lang === LANGUAGES.DEFAULT ? 'es' : LANGUAGES.DEFAULT);
                }
                hideConformationDialog() {
                    this.widgets.confirmationdialog.visible = false;
                    Router.focusPage();
                }
            },
        ];
    }
}
