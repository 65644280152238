import { Storage } from '@lightningjs/sdk';
import { MPARTICLE_ERROR_CONTEXTS, ROUTE, STORAGE_KEYS } from '../../../../constants';
import { getAdjustedReferringShelf } from '../../../../helpers';
import { EVENTS } from '../../../../lib/analytics/types';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate';
import { sendMetric } from '../../../../lib/analytics/Analytics';
import RouterUtil from '../../../../util/RouterUtil';
import AuthenticationSingleton from '../../../../authentication/Authentication';
import UserInteractionsStoreSingleton from '../../../../store/UserInteractions';
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { isSingleProgram, isSingleStream } from '../../../../store/PlayerStore/actions';
import { useProgress } from '../../../../components/player/PlayerControls/hooks/useProgress';
export class VODAnalyticsDelegate extends AnalyticsWithEndCardDelegate {
    constructor() {
        super(...arguments);
        this._progress = useProgress();
    }
    _getAnalyticsData() {
        var _a;
        const { program } = PlayerStoreSingleton;
        if (!isSingleProgram(program))
            return {};
        return {
            adobeBrand: program === null || program === void 0 ? void 0 : program.adobeBrand,
            adobeVideoResearchTitle: program === null || program === void 0 ? void 0 : program.adobeVideoResearchTitle,
            listOfGenres: program === null || program === void 0 ? void 0 : program.listOfGenres,
            secondaryGenre: program === null || program === void 0 ? void 0 : program.secondaryGenre,
            sport: program === null || program === void 0 ? void 0 : program.sport,
            league: program === null || program === void 0 ? void 0 : program.league,
            isOlympics: program === null || program === void 0 ? void 0 : program.isOlympics,
            entitlement: program === null || program === void 0 ? void 0 : program.entitlement,
            language: program === null || program === void 0 ? void 0 : program.language,
            durationInMilliseconds: program === null || program === void 0 ? void 0 : program.durationInMilliseconds,
            startTime: ((_a = this._ctx._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a._previousLogTime) || 0,
            resumeTime: this.resumeTime || 0,
            programTitle: program === null || program === void 0 ? void 0 : program.programTitle,
        };
    }
    get resumeTime() {
        const { stream } = PlayerStoreSingleton;
        if (!isSingleStream(stream))
            return 0;
        const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(stream === null || stream === void 0 ? void 0 : stream.v4ID);
        return percentViewed < 0.95 ? percentViewed * ((stream === null || stream === void 0 ? void 0 : stream.duration) || 0) : 0;
    }
    getErrorAnalytics(error, errorEvent) {
        var _a, _b;
        const { stream } = PlayerStoreSingleton;
        const mvpd = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.mvpd;
        const code = (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.code) || (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.type);
        const description = (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.description) || (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.details) || (error === null || error === void 0 ? void 0 : error.title);
        return {
            video: Object.assign(Object.assign({}, (stream ? stream : {})), { analytics: this._getAnalyticsData(), currentTime: this._progress.value[0] }),
            player: this._ctx._player,
            errorType: 'Video',
            description,
            friendlyMessage: (_b = error === null || error === void 0 ? void 0 : error.message) === null || _b === void 0 ? void 0 : _b.replace('%{brand}', stream === null || stream === void 0 ? void 0 : stream.brandDisplayTitle),
            code,
            mvpd,
            source: MPARTICLE_ERROR_CONTEXTS.vod,
            system: 'Other',
            stack: errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.stack,
            fatal: true,
            isLive: false,
        };
    }
    firePageLoad() {
        sendMetric(EVENTS.PAGE_LOAD, Object.assign(Object.assign({}, PlayerStoreSingleton.program), { regRef: RouterUtil.checkGetRegistrationReferrer(), path: ROUTE.video }));
    }
    fireSessionInit() {
        // no op
    }
    fireSessionStart(payload = {}) {
        sendMetric(EVENTS.VOD_SESSION_START, Object.assign(Object.assign(Object.assign({}, this._getMetricsData()), payload), { ccLanguage: ClosedCaptionsUtils.getCCType() }));
    }
    fireSessionEnd(payload = {}) {
        const [currentTime, duration] = this._progress.value;
        sendMetric(EVENTS.VOD_SESSION_END, Object.assign(Object.assign({ ccLanguage: ClosedCaptionsUtils.getCCType(), duration, watched: currentTime }, this._getMetricsData()), payload));
    }
    fireContentComplete() {
        // no op
    }
    _getMetricsData() {
        return Object.assign(Object.assign(Object.assign({}, PlayerStoreSingleton.stream), this._getAnalyticsData()), { shelf: getAdjustedReferringShelf(), smartTile: Storage.get(STORAGE_KEYS.SMART_TILE), authType: this._ctx.authtype, tokenType: this._ctx.tokenType });
    }
}
