import { Router } from '@lightningjs/sdk';
import { pushHash, setSmooth } from '../../../helpers';
import ContentPosition from '../../../util/contentPosition';
export const FiltersStateFactory = (base) => class Filters extends base {
    _getFocused() {
        return this.tag('Filters') || this;
    }
    _handleDown() {
        if (this.tag('Results').children.length)
            this._setState('Results');
    }
    _handleEnter() {
        const newIndex = this.tag('Filters').focusIndex;
        const { value, localStartTimestamp, localEndTimestamp } = this._dates[newIndex];
        const hash = Router.getActiveHash() || '';
        const [base = ''] = hash.split('/');
        ContentPosition.clearPositionForCurrentPage();
        if (value !== 'all') {
            const [match, year, month, day] = new RegExp(/(\d{4})-(\d{2})-(\d{2})/g).exec(value) || Array(4).fill(null);
            if (match) {
                const date = `${month}${day}${year}`;
                pushHash(`${base}/${date}`);
            }
        }
        else {
            pushHash(base);
        }
        this.stage.gc();
        if (this.tag('Results'))
            setSmooth(this.tag('Results'), 'visible', 0);
        setSmooth(this.widgets.loader, 'visible', 1);
        this.fetchByFilter(value === 'all' ? 0 : { localStartTimestamp, localEndTimestamp });
    }
};
