import { Utils } from '@lightningjs/sdk';
import SettingsButton from './SettingsButton';
export default class SelectButton extends SettingsButton {
    constructor() {
        super(...arguments);
        this._ancestor = '$valueChanged';
    }
    _construct() {
        super._construct();
        this.selected = false;
    }
    set selected(v) {
        this._selected = v;
        this.changeIcon();
    }
    get selected() {
        return this._selected;
    }
    set ancestor(ancestor) {
        this._ancestor = ancestor;
    }
    set optionValue(v) {
        this._optionValue = v;
    }
    get optionValue() {
        return this._optionValue;
    }
    set focusSelectedIcon(v) {
        this._focusSelectedIcon = v;
    }
    changeIcon() {
        if (!this.tag('Image'))
            return;
        this.tag('Image').patch({
            src: Utils.asset(`images/settings/${this.selected ? 'selected' : 'selection'}.png`),
        });
    }
    _handleEnter() {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        this.fireAncestors(this._ancestor, this.optionValue);
    }
}
