import { Router } from '@lightningjs/sdk';
import { CREDITS_FLOW_MODAL_TYPE, ENTITLEMENT, ROUTE } from '../constants';
import { ContentGatingType } from '../api/types/bffTypes';
import ModalManager, { ModalTypes } from '../lib/ModalManager';
import AuthenticationSingleton, { AuthenticationEvents } from '../authentication/Authentication';
import { getIdentityRegCode, getUserProfile } from '../api/Identity';
import { ACTIVATION_TYPE } from '../widgets/Modals/activation/constants';
import { isNBCProfileLinked } from './auth';
export const pushHash = (hash) => history.pushState(null, '', `#${hash}`);
export const isOlympicsPage = () => Router.getActiveRoute() === ROUTE.olympics;
/**
 * This will redirect to content gating modal if applicable
 * @param stream Stream object with contentGatingType and entitlement properties
 * @param program Program data
 * @returns A promise which resolves if playback can start
 * (true if content gating flow completed, false if content gating not applicable)
 */
export const checkContentGating = (stream, program) => new Promise((res, rej) => {
    const isAuthenticated = AuthenticationSingleton.isAuthenticated() || isNBCProfileLinked();
    if (isAuthenticated ||
        (stream === null || stream === void 0 ? void 0 : stream.entitlement) === ENTITLEMENT.ENTITLED ||
        (stream === null || stream === void 0 ? void 0 : stream.contentGatingType) === ContentGatingType.NOT_APPLICABLE) {
        res(false);
        return;
    }
    const successCb = () => res(true);
    getIdentityRegCode().then(({ deviceCode }) => {
        const modalType = (stream === null || stream === void 0 ? void 0 : stream.contentGatingType) === ContentGatingType.HARD
            ? ModalTypes.HARD_CONTENT_GATING
            : ModalTypes.SOFT_CONTENT_GATING;
        AuthenticationEvents.addSingleListener(ACTIVATION_TYPE.NBC, successCb);
        AuthenticationEvents.pollIDM(deviceCode);
        ModalManager.open(modalType, {
            type: stream.contentGatingType,
            resolveCallback: () => {
                AuthenticationEvents.removeSingleListener(ACTIVATION_TYPE.NBC, successCb);
                res(true);
            },
            rejectCallback: rej,
            stream,
            program,
        });
    });
});
export const backToFirstRouteThatIsnt = (route) => {
    var _a;
    const exclusions = Array.isArray(route) ? route : [route];
    const check = (r) => exclusions.includes(r);
    let step = -1;
    const history = Router.getHistory();
    for (let i = history.length - 1, n = 0; i > n; i--) {
        if (!check((_a = history[i]) === null || _a === void 0 ? void 0 : _a.hash)) {
            step = -(history.length - i);
            break;
        }
    }
    Router.go(step);
};
export const checkCreditsFlow = async (stream, program, bypass) => new Promise((res, rej) => {
    const isMVPD = AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isMvpdTempPass();
    const userProfile = getUserProfile();
    const isAvailableForCredits = 'availableForCredits' in stream && stream.availableForCredits;
    const isEntitled = stream.entitlement === ENTITLEMENT.ENTITLED;
    const isAbleToUseCredits = userProfile &&
        (userProfile.isVideoAlreadyPlayed(program === null || program === void 0 ? void 0 : program.mpxGuid) ||
            (isAvailableForCredits && userProfile.episodeCount > 0));
    if (isMVPD || !isEntitled || bypass || isAbleToUseCredits) {
        res(false);
        return;
    }
    const getCreditsFlowType = () => {
        if (!userProfile) {
            return isAvailableForCredits
                ? CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_UNAUTHORIZED
                : CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED;
        }
        return isAvailableForCredits
            ? CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_AUTHORIZED
            : CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED;
    };
    ModalManager.open(ModalTypes.CREDITS_FLOW_MODAL, {
        resolveCallback: res,
        rejectCallback: rej,
        stream,
        program,
        type: getCreditsFlowType(),
    });
});
