import { checkCreditsFlow } from '../../../../helpers';
import { missingDataError, StreamLoaderErrors } from '../error';
export const creditsFlow = (bypass) => async (request, stream, program) => {
    try {
        if (!stream)
            throw missingDataError('Stream is undefined');
        const recheck = await checkCreditsFlow(stream, program, bypass);
        /**
         * If a user is logged in with the profile,
         * we need to make sure they have enough credits to watch content.
         * Well, let's double-check that.
         */
        if (recheck)
            await checkCreditsFlow(stream, program, bypass);
        return request;
    }
    catch (e) {
        request.error = {
            handled: true,
            detail: StreamLoaderErrors.USER_CANCELLED,
            data: e,
        };
        throw request;
    }
};
