import { IPlayerEvent } from './IPlayerEvent';
import { PlayerError } from '../../../components/error/PlayerError';
import { PlayerErrorCategory } from '@sky-uk-ott/core-video-sdk-js-core/lib/error/error.enums';
import TVPlatform from '../../../lib/tv-platform';
export class FatalErrorEvent extends IPlayerEvent {
    constructor(error) {
        super();
        this._error = error;
    }
    get error() {
        return this._error;
    }
    get errorType() {
        var _a;
        switch (true) {
            case (this.error.category === PlayerErrorCategory.VSF &&
                ((_a = this.error.code) === null || _a === void 0 ? void 0 : _a.includes('3016')) &&
                TVPlatform.getAllowVSFError()):
                return PlayerError.VSF_3016;
            default:
                return PlayerError.UNKNOWN;
        }
    }
}
